import React from "react";


function FaqBg() {
    return (
        <>
            <img
                className="d-block w-100"
                src={`/img/faq.png`}
                alt="FAQ"
                />
        </>
        
    );
        
}

export default FaqBg;
